.container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 88px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

.templateContainer {
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.buttonContainer {
  display: flex;
  width: 100%;
  padding: 8px;
  gap: 8px;
  background-color: #fff;
}

.shipmentButton {
  border: 1px solid #808080;
  color: #808080;
  border-radius: 20px;
  padding: 8px 12px;
  background-color: #fff;
}

.shipmentButtonSelected {
  border: 1px solid #000;
  color: #000;
}

.content > table {
  height: 100%;
}

.pageBody::-webkit-scrollbar {
  width: 10px;
}
.pageBody::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.pageBody::-webkit-scrollbar-thumb {
  background: #888;
}

.pageBody::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.pageBody ::-webkit-scrollbar {
  width: 10px;
}

.pageBody ::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.pageBody ::-webkit-scrollbar-thumb {
  background: #888;
}

.pageBody::-webkit-scrollbar-thumb:hover {
  background: #555;
}
