.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.react-datepicker__header {
  min-height: 90px !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: space-between !important;
  background: none !important;
  border-bottom: none !important;
}

.react-datepicker-popper {
  /* transform: none !important; */
  /* position: relative !important; */
}

.react-datepicker__triangle {
  display: none !important;
}

.react-datepicker__day {
  height: 28px !important;
  width: 28px !important;
}

.react-datepicker__day-name {
  height: 28px !important;
  width: 28px !important;
}

.react-datepicker__day--range-end,
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--in-range {
  background-color: #2563eb !important;
}

.react-datepicker-popper {
  z-index: 300 !important;
}

.reactMarkdown a {
  text-decoration: underline;
}
