.container {
  position: inherit;
  left: 0;
  bottom: 0;
  height: 80px;
  width: 100%;
  padding: 32px;
  background-color: #f5f6f7;
  display: flex;
  justify-content: space-between;
}

.containerHomePage {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 60px;
  width: 100%;
  padding: 18px;
  background-color: #f5f6f7;
  display: flex;
  justify-content: space-between;
}
